import {
  DollarCircleOutlined,
  DownCircleOutlined,
  CalendarOutlined,
} from "@ant-design/icons";
import React from "react";
import CardImage from "../../components/CardImage";
import { moneyPipe, formatDDMMYYY } from "../../Utils";

import {
  AccordionDetails,
  AccordionSummary,
  Container,
  Typography,
} from "@mui/material";
import {
  BalanceContainer,
  BalanceText,
  BalanceDetailText,
  Title,
  CustomAccordion,
} from "./styles";
import { ComissionModel } from "../../models";
import { useQuery } from "react-query";
import { Api } from "../../services/api";

const Comission: React.FC = () => {
  const { data } = useQuery<ComissionModel>("comission", async () => {
    const response = await Api.get("comissao/agricultor");
    return response.data;
  });

  const getTotalValue = (comission: ComissionModel | null): number => {
    return comission
      ? comission!.valuePaidFarm + comission!.valueAssociation
      : 0;
  };

  return (
    <>
      {data && (
        <Container>
          <CardImage
            icon={
              <DollarCircleOutlined
                style={{ color: "gray", fontSize: "18px" }}
              />
            }
            title="Comissão Atual"
          >
            <BalanceContainer>
              <BalanceDetailText>
                {moneyPipe(getTotalValue(data))}
              </BalanceDetailText>
              <BalanceDetailText>
                - {moneyPipe(data.valueAssociation)} Taxa de Porcentagem
              </BalanceDetailText>
              {data.fixedMonthlyFee > 0 && (
                  <BalanceDetailText>
                    - {moneyPipe(data.fixedMonthlyFee)} Taxa fixa do(s)
                    mês(es) {data.referringMonth}
                  </BalanceDetailText>
                )}
              <BalanceText>{moneyPipe(data.valuePaidFarm)}</BalanceText>
            </BalanceContainer>
          </CardImage>

          {data.historic.length > 0 && <Title>Comissões anteriores</Title>}
          {data.historic.map((comission) => (
            <CustomAccordion key={comission.id}>
              <AccordionSummary
                expandIcon={<DownCircleOutlined />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>
                  <CalendarOutlined /> {formatDDMMYYY(comission.payDate!)}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <BalanceDetailText>
                  {moneyPipe(getTotalValue(comission))}
                </BalanceDetailText>
                <BalanceDetailText>
                  - {moneyPipe(comission.valueAssociation)} Taxa de Porcentagem
                </BalanceDetailText>
                {comission.fixedMonthlyFee > 0 && (
                  <BalanceDetailText>
                    - {moneyPipe(comission.fixedMonthlyFee)} Taxa fixa do(s)
                    mês(es) {comission.referringMonth}
                  </BalanceDetailText>
                )}
                <BalanceText>{moneyPipe(comission.valuePaidFarm)}</BalanceText>
              </AccordionDetails>
            </CustomAccordion>
          ))}
        </Container>
      )}
    </>
  );
};

export default Comission;
