import React from "react";

import {
  Container,
  CardInformation,
  ProductText,
  ImageContainer,
} from "./styles";
import DangerIcon from "../../assets/stockPageIcons/dangerIcon.png";
import WarningIcon from "../../assets/stockPageIcons/warningIcon.png";
import SuccessIcon from "../../assets/stockPageIcons/successIcon.png";
import {
  CardProps,
  StatusColorsReference,
  StatusImageReference,
} from "./stockTypes";

const StockCard: React.FC<CardProps> = ({
  productName,
  currentQuantity,
  cardStatus = "success",
}) => {
  const statusColors: StatusColorsReference = {
    success: "#14a65d",
    warning: "hsl(34, 97%, 64%)",
    danger: "hsl(0, 78%, 62%)",
  };
  const statusIcon: StatusImageReference = {
    success: <img src={SuccessIcon} alt="Estoque Farto" />,
    warning: <img src={WarningIcon} alt="Estoque em ponto de encomenda" />,
    danger: <img src={DangerIcon} alt="Estoque Zerado" />,
  };
  return (
    <Container>
      <CardInformation cardStatus={statusColors[cardStatus]}>
        <ProductText>{productName}</ProductText>
        {currentQuantity}
      </CardInformation>
      <ImageContainer>{statusIcon[cardStatus]}</ImageContainer>
    </Container>
  );
};

export default StockCard;
