import React, { useMemo } from "react";

import { useAuth } from "../../contexts/AuthProvider/useAuth";
import { Api } from "../../services/api";
import { getUserOnLocalStorage } from "../../contexts/AuthProvider/util";
import { Outlet } from "react-router-dom";
import Navbar from "../Navbar";
import Header from "../Header";

function ProtectedLayout() {
  const { LogOut } = useAuth();

  useMemo(() => {
    Api.interceptors.request.use(
      (config) => {
        const user = getUserOnLocalStorage();
        config.headers = {
          Authorization: `Bearer ${user?.token}`,
        };
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    Api.interceptors.response.use(
      (response) => response,
      (error) => {
        const status = error.status || error.response.status;
        if (status === 403 || status === 401) {
          LogOut();
        }
        return Promise.reject(error);
      }
    );
  }, [LogOut]);

  return (
    <>
      <Header />
      <Outlet />
      <Navbar />
    </>
  );
}

export default ProtectedLayout;
