import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

import { LogoutOutlined } from "@ant-design/icons";
import logo from "../../assets/logo.png";
import { useAuth } from "../../contexts/AuthProvider/useAuth";

import { Container, Title, LogoutContainerButton } from "./styles";
import { Avatar } from "@mui/material";

const Header = () => {
  const { LogOut, userName } = useAuth();

  const handleLogout = () => {
    LogOut();
  };

  return (
    <AppBar color="default" position="fixed">
      <Container>
        <Box>
          <Avatar src={logo} />
        </Box>
        <Box>
          <Title>{userName || ''}</Title>
        </Box>
        <Box>
          <Tooltip title="Sair">
            <IconButton onClick={handleLogout} sx={{ p: 0 }}>
              <LogoutContainerButton>
                <LogoutOutlined />
              </LogoutContainerButton>
            </IconButton>
          </Tooltip>
        </Box>
      </Container>
    </AppBar>
  );
};
export default Header;
