import React, { useState, useEffect } from "react";

import {
  Container,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { SearchOutlined } from "@ant-design/icons";
import StockCard from "../../components/StockCard";
import { useQuery } from "react-query";
import { Api } from "../../services/api";
import { Product } from "../../models";

import { filterData } from "../../Utils";

const Stock: React.FC = () => {
  const [searchInput, setSearchInput] = useState<string>("");
  const [filteredData, setFilteredData] = useState<Product[]>([]);
  const [sortedData, setSortedData] = useState<Product[]>([]);
  const { data } = useQuery<Product[]>("stock", async () => {
    const response = await Api.get("produtos-propriedade/estoque");
    return response.data;
  });
  useEffect(() => {
    if (!data) {
      return;
    }
    filterProducts(searchInput, data);
  }, [searchInput, data]);
  useEffect(() => {
    const sortProductsPerStatus = (): void => {
      const dangerProducts: Product[] = [];
      const warningProducts: Product[] = [];
      const successProducts: Product[] = [];
      filteredData.forEach((product) => {
        if (product.stockStatus === "success") {
          successProducts.push(product);
          return;
        }
        if (product.stockStatus === "danger") {
          dangerProducts.push(product);
          return;
        }
        if (product.stockStatus === "warning") {
          warningProducts.push(product);
          return;
        }
      });
      setSortedData(
        Array.prototype.concat(dangerProducts, warningProducts, successProducts)
      );
    };
    sortProductsPerStatus();
  }, [filteredData]);

  const filterProducts = (value: string, data: Product[]): void => {
    setFilteredData(filterData<Product>(value, data, ["productName"]));
  };
  return (
    <Container>
      <Grid
        container
        spacing={1}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <FormControl sx={{ m: 1, width: "25ch" }} variant="outlined">
          <InputLabel htmlFor="outlined-adornment-amount">Pesquisar</InputLabel>
          <OutlinedInput
            id="outlined-adornment-amount"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            endAdornment={
              <InputAdornment position="start">
                <SearchOutlined />
              </InputAdornment>
            }
            label="Pesquisar"
          />
        </FormControl>
      </Grid>
      {sortedData.map((product) => (
        <StockCard
          key={product.id}
          productName={product.productName}
          currentQuantity={
            Math.round((product.amount + Number.EPSILON) * 100) / 100 +
            product.measurementUnity
          }
          cardStatus={product.stockStatus!}
        ></StockCard>
      ))}
    </Container>
  );
};

export default Stock;
