import React, { useState } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useForm } from "react-hook-form";
import { useAuth } from "../../contexts/AuthProvider/useAuth";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import ReactPWAInstallProvider, { useReactPWAInstall } from "react-pwa-install";
import logoImage from "../../assets/logo.png";
import { Logo, ChangePasswordButton, FieldsetForm } from "./styles";
import Copyright from "./components/Copyright";
import ChangePasswordModal from "./components/ChangePasswordModal";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const theme = createTheme();

export default function SignIn() {
  const { LogIn } = useAuth();
  const { register, handleSubmit } = useForm();
  const [open, setOpen] = useState<boolean>(false);
  const [isRecovery, setIsRecovery] = useState<boolean>(false);
  const openRecoveryModal = () => {
    setIsRecovery(true);
    setOpen(true);
  };
  const openRegisterModal = () => {
    setIsRecovery(false);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [errorMessageToastr, setErrorMessageToastr] = useState<boolean>(false);
  const [installAppSuccessMessage, setInstallAppSuccessMessage] =
    useState<boolean>(false);
  const [installAppMessage, setInstallAppMessage] = useState<boolean>(true);
  const { pwaInstall, supported, isInstalled } = useReactPWAInstall();

  const handleInstallApp = () => {
    pwaInstall({
      title: "Instalar Aplicativo",
      logo: logoImage,
      features: (
        <ul>
          <li>Uso fácil</li>
          <li>Consulta de informações de Vendas, comissões, estoque, etc</li>
        </ul>
      ),
      description: "Tudo vai ficar mais fácil depois de instalar &#128521;",
    })
      .then(() =>
        alert("App installed successfully or instructions for install shown")
      )
      .catch(() => alert("User opted out from installing"));
  };

  async function handleLogin(formData: {
    email: string;
    password: string;
  }): Promise<void> {
    setIsFetching(true);
    try {
      await LogIn(formData.email.trim(), formData.password.trim());
    } catch (error) {
      setErrorMessageToastr(true);
      setIsFetching(false);
    }
  }

  function handleForgotPassword(event: any) {
    event.preventDefault();
    openRecoveryModal();
  }

  function handleRegisterAccount(event: any) {
    event.preventDefault();
    openRegisterModal();
  }

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <FieldsetForm disabled={isFetching}>
          <CssBaseline />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Logo src={logoImage} />
            <Typography component="h1" variant="h5">
              Bem vindo, agricultor!
            </Typography>
            <Typography component="span" color="gray">
              Faça login para consultar as informações &#128521;
            </Typography>
            <Box
              component="form"
              sx={{ mt: 1 }}
              onSubmit={handleSubmit(handleLogin)}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email"
                autoComplete="email"
                disabled={isFetching}
                autoFocus
                {...register("email", {
                  min: 3,
                })}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                label="Senha"
                type="password"
                id="password"
                disabled={isFetching}
                autoComplete="current-password"
                {...register("password", {
                  min: 3,
                })}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                disabled={isFetching}
                sx={{ mt: 3, mb: 2 }}
              >
                Acessar
              </Button>
              <Grid container>
                <Grid item xs>
                  <ChangePasswordButton
                    onClick={handleForgotPassword}
                  >
                    🔑 Esqueceu a senha?
                  </ChangePasswordButton>
                </Grid>
                <Grid item>
                  <ChangePasswordButton
                    onClick={handleRegisterAccount}
                  >
                    "Ainda não tem conta?"
                  </ChangePasswordButton>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Copyright sx={{ mt: 8, mb: 4 }} />
        </FieldsetForm>
      </Container>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={errorMessageToastr}
        autoHideDuration={2500}
        onClose={() => setErrorMessageToastr(false)}
      >
        <Alert
          onClose={() => setErrorMessageToastr(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          Email ou senha incorretos
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={installAppSuccessMessage}
        autoHideDuration={2500}
        onClose={() => setInstallAppSuccessMessage(false)}
      >
        <Alert
          onClose={() => setInstallAppSuccessMessage(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          Agora é só acessar o aplicativo pela tela de início do celular!!
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={installAppMessage && !isInstalled && supported}
        onClose={() => setInstallAppMessage(false)}
      >
        <Alert
          sx={{ width: "100%" }}
          severity="info"
          action={
            <Button color="inherit" onClick={handleInstallApp}>
              Instalar
            </Button>
          }
        >
          Você pode instalar Aplicativo em seu dispositivo
        </Alert>
      </Snackbar>
      <ChangePasswordModal
        open={open}
        onClose={handleClose}
        close={() => setOpen(false)}
        isRecovery={isRecovery}
      />
    </ThemeProvider>
  );
}
