export const getCurrentMonth = (): string => {
  const date = new Date();
  return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
    2,
    "0"
  )}`;
};

export const moneyPipe = (value: number): string => {
  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(value);
};

export const formatDDMMYYY = (date: string): string => {
  const dateObject = new Date(date);
  return [
    String(dateObject.getDate()).padStart(2, "0"),
    String(dateObject.getMonth() + 1).padStart(2, "0"),
    String(dateObject.getFullYear()).padStart(2, "0"),
  ].join("/");
};

export const removeAccents = (value: string): string => {
  value = value.replace(/[ÀÁÂÃ]/, "A");
  value = value.replace(/[àáâã]/, "a");
  value = value.replace(/[ÈÉÊË]/, "E");
  value = value.replace(/[èéê]/, "e");
  value = value.replace(/[ÌÍ]/, "I");
  value = value.replace(/[ìí]/, "i");
  value = value.replace(/[ÒÓ]/, "O");
  value = value.replace(/[òó]/, "o");
  value = value.replace(/[ÙÚ]/, "U");
  value = value.replace(/[ùú]/, "u");
  value = value.replace(/[Ç]/, "C");
  value = value.replace(/[ç]/, "c");

  return value;
};

export const prepareStringToFilter = (value: string): string => {
  return removeAccents(value).toLowerCase();
};

export const getObjectKeywords = (element: any, keywords: string[]): string => {
  return keywords.map(keyword => element[keyword]).join(' ');
}

export const filterData = <T>(
  value: string,
  data: T[],
  keywords: string[]
): T[] => {
  return data.filter(
    (element) =>
      prepareStringToFilter(getObjectKeywords(element, keywords)).indexOf(
        prepareStringToFilter(value)
      ) !== -1
  );
};
