import React from "react";

import { useLocation } from "react-router-dom";

import {
  NavBarContainer,
  List,
  Item,
  LinkButton,
  Icon,
  Title,
  ActiveIndicator,
} from "./styles";

import {
  HomeOutlined,
  InboxOutlined,
  DollarCircleOutlined,
  RiseOutlined,
} from "@ant-design/icons";

const Navbar: React.FC = () => {
  const { pathname } = useLocation();
  const items = [
    {
      id: 0,
      title: "Início",
      route: "/",
      icon: () => <HomeOutlined />,
    },
    {
      id: 1,
      title: "Estoque",
      route: "/estoque",
      icon: () => <InboxOutlined />,
    },
    {
      id: 2,
      title: "Comissões",
      route: "/comissoes",
      icon: () => <DollarCircleOutlined />,
    },
    {
      id: 3,
      title: "Vendas",
      route: "/vendas",
      icon: () => <RiseOutlined />,
    },
  ];

  return (
    <NavBarContainer>
      <List>
        {items.map((item) => (
          <Item
            key={item.id}
            className={`${item.route === pathname ? "active" : ""}`}
          >
            <LinkButton to={item.route}>
              <Icon>{item.icon()}</Icon>
              <Title>{item.title}</Title>
            </LinkButton>
          </Item>
        ))}
        <ActiveIndicator
          className={[
            pathname === items[0].route ? "active1" : "",
            pathname === items[1].route ? "active2" : "",
            pathname === items[2].route ? "active3" : "",
            pathname === items[3].route ? "active4" : "",
          ].join(" ")}
        />
      </List>
    </NavBarContainer>
  );
};

export default Navbar;
