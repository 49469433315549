import React, { createContext, useEffect, useState } from "react";

import { IAuthProvider, IContext, IUser } from "./types";
import {
  getUserOnLocalStorage,
  loginRequest,
  setUserOnLocalStorage,
} from "./util";

export const AuthContext = createContext<IContext>({} as IContext);

export const AuthProvider = ({ children }: IAuthProvider) => {
  const [user, setUser] = useState<IUser | null>();

  useEffect(() => {
    const user = getUserOnLocalStorage();

    if (user) {
      setUser(user);
    }
  }, []);

  async function LogIn(login: string, password: string) {
    const response = await loginRequest(login, password);
    const payload: IUser = {
      email: login,
      token: response.token,
      userName: response.userName,
    };
    setUser(payload);
    setUserOnLocalStorage(payload);
  }

  function LogOut() {
    setUser(null);
    setUserOnLocalStorage(null);
  }

  return (
    <AuthContext.Provider
      value={{
        ...user,
        signed: Boolean(user),
        LogIn,
        LogOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
