import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import ProtectedLayout from "../components/ProtectedLayout";

import Home from "../pages/Home";
import Stock from "../pages/Stock";
import Comission from "../pages/Comission";
import Sales from "../pages/Sales";
import { QueryClientProvider } from "react-query";
import { queryClient } from "../services/queryClient";

const AuthenticatedRoutes: React.FC = () => {
  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <Routes>
          <Route element={<ProtectedLayout />}>
            <Route path="/" element={<Home />} />
            <Route path="/estoque" element={<Stock />} />
            <Route path="/comissoes" element={<Comission />} />
            <Route path="/vendas" element={<Sales />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Route>
        </Routes>
      </QueryClientProvider>
    </BrowserRouter>
  );
};

export default AuthenticatedRoutes;
