import styled from "styled-components";
import { Link } from "@mui/material";

export const FieldsetForm = styled.fieldset`
  margin: 0;
  padding: 0;
  border: none;
`;

export const Logo = styled.img`
  width: 100px;
  height: 100px;
  margin-bottom: 18px;
`;

export const ChangePasswordButton = styled.button`
  margin: 0;
  padding: 0;
  outline: none;
  border: none;
  background: transparent;
  text-decoration: underline;
  color: #3700B3;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:disabled {
    color: lightGray;
  }
`;
