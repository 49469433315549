import styled from "styled-components";
import TextField from "@mui/material/TextField";

export const InputContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: auto;
  margin-right: 22px;
`;

export const InputLabel = styled.label`
  color: lightGray;
  float: right;
`;

export const MonthInput = styled(TextField).attrs({
  type: "month"
})`
  input {
    padding: 3px;
    color: #8f8f8f;
  }
`;

export const SaleCardContainer = styled.div`
  margin-top: 0;
`;

export const CardDescription = styled.p`
  color: gray;
  font-size: small;
`;

export const NotFoundDescription = styled.p`
  color: lightGray;
  font-size: small;
`;
