import React, { ReactNode, useEffect, useState } from "react";
import { Container, Theme } from "@mui/material";
import CardImage from "../../components/CardImage";
import {
  InputContainer,
  MonthInput,
  SaleCardContainer,
  CardDescription,
  NotFoundDescription,
} from "./styles";
import { getCurrentMonth } from "../../Utils";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";

import { Product } from "../../models";

import { RiseOutlined } from "@ant-design/icons";
import { Api } from "../../services/api";
import { useQuery } from "react-query";
import makeStyles from "@material-ui/core/styles/makeStyles";
import createStyles from "@material-ui/core/styles/createStyles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chipContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-start",
    },
    chip: {
      flex: '1',
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    quantityContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    '@global': {
      '.chipContainer > span': {
        display: 'block',
        flex: '1'
      },
    },
  })
);

const SaleItemComponent: React.FC<Product> = ({
  amount,
  measurementUnity,
  productName,
}) => {
  const classes = useStyles();
  const productText: ReactNode = (
    <div className={classes.chip}>
      <div>{productName || ""}</div>
      <div className={classes.quantityContainer}>
        {amount || 0} {measurementUnity || ""}
      </div>
    </div>
  );
  return (
    <Chip
      color="primary"
      variant="outlined"
      label={productText}
      className={classes.chipContainer + ' chipContainer'}
    />
  );
};

const Sales: React.FC = () => {
  const { data, refetch } = useQuery<Product[]>(
    "sales",
    async () => {
      const response = await Api.get("produtos-propriedade/vendas", {
        params: { date: month + "-01" },
      });

      return response.data.sort(function (a: Product, b: Product) {
        if (a.productName > b.productName) {
          return 1;
        }
        if (a.productName < b.productName) {
          return -1;
        }
        return 0;
      });
    },
    { staleTime: 1000 * 60 }
  );
  const [month, setMonth] = useState<string>(getCurrentMonth());
  const handleChangeMonth = async (data: string) => {
    data ? setMonth(data) : setMonth(getCurrentMonth());
  };
  useEffect(() => {
    refetch();
  }, [month]);

  return (
    <Container>
      <InputContainer>
        <MonthInput
          id="outlined-size-small"
          value={month}
          onChange={(e) => handleChangeMonth(e.target.value)}
          size="small"
          InputProps={{
            inputProps: { min: "2022-01", max: getCurrentMonth() },
          }}
        />
      </InputContainer>
      <CardImage
        icon={<RiseOutlined style={{ color: "gray", fontSize: "18px" }} />}
        title="Vendas do Mês"
      >
        <SaleCardContainer>
          <CardDescription>
            &#129351; Abaixo estão listados todos os <strong>Produtos</strong> e
            quanto você vendeu de cada um
          </CardDescription>
          {data?.length === 0 && (
            <NotFoundDescription>
              Você não vendeu nenhum produto no mês informado
            </NotFoundDescription>
          )}
          <Stack direction="column" spacing={1}>
            {data?.sort().map((SaleItem) => (
              <SaleItemComponent
                key={SaleItem.id}
                amount={
                  Math.round((SaleItem.amount + Number.EPSILON) * 100) / 100
                }
                measurementUnity={SaleItem.measurementUnity}
                productName={SaleItem.productName}
                id={SaleItem.id}
              />
            ))}
          </Stack>
        </SaleCardContainer>
      </CardImage>
    </Container>
  );
};

export default Sales;
