import React, { useState } from "react";

import { Title, Description } from "./styles";
import { Box, Modal, Button, TextField, Snackbar } from "@mui/material";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { useForm } from "react-hook-form";
import { Api } from "../../../../services/api";

type ChangePasswordModalProps = {
  open: boolean;
  close: () => unknown;
  onClose?: () => unknown;
  isRecovery: boolean;
};

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
  borderRadius: "4px",
};

const ChangePasswordModal: React.FC<ChangePasswordModalProps> = ({
  isRecovery = false,
  close,
  ...materialProps
}) => {
  const { register, handleSubmit } = useForm();
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<boolean>(false);

  const handleChangePassword = async ({ email }: { email: string }) => {
    const url = "http://172.16.36.1:3000/senha";
    const requestUrl = isRecovery
      ? "propriedade/recuperacao-senha"
      : "propriedade/confirmacao-conta";
    setIsFetching(true);

    try {
      const response = await Api.post(requestUrl, {
        email,
        url,
      });
    } catch (error) {
      console.log(error);
    }

    setIsFetching(false);
    setSuccessMessage(true);
    close();
  };

  return (
    <>
      <Modal {...materialProps}>
        <Box
          component="form"
          sx={style}
          onSubmit={handleSubmit(handleChangePassword)}
        >
          <Title>
            🔑 {isRecovery ? "Alteração de senha" : "Criação de Conta"}
          </Title>
          <Description>
            {isRecovery
              ? "Para alterar a sua senha "
              : "Para realizar o cadastro "}
            vamos precisar do seu e-mail, o mesmo que foi informado no cadastro
            no sistema da associação 📬
          </Description>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            type="email"
            autoComplete="email"
            disabled={isFetching}
            autoFocus
            {...register("email", {
              min: 3,
            })}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            disabled={isFetching}
            sx={{ mt: 3, mb: 2 }}
          >
            Enviar
          </Button>
        </Box>
      </Modal>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={successMessage}
        onClose={() => setSuccessMessage(false)}
      >
        <Alert
          onClose={() => setSuccessMessage(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          Um email foi enviado a você, lá terão mais instruções para continuar.
        </Alert>
      </Snackbar>
    </>
  );
};

export default ChangePasswordModal;
