import React from "react";

import {
  Container,
  CardButton,
  TextContainer,
  CardTitle,
  CardDescription,
  ImageContainer
} from "./styles";

type CardProps = {
  icon?: JSX.Element;
  title?: string;
  description?: string;
  image?: JSX.Element;
  link?: string;
  children?: JSX.Element;
};

const CardImage: React.FC<CardProps> = ({
  icon,
  title,
  description,
  image,
  link,
  children
}) => {
  return (
    <Container to={link || ""}>
      <CardButton hasImage={!!image}>
        <TextContainer>
          {icon}
          {title && <CardTitle>{title}</CardTitle>}
          {description && <CardDescription>{description}</CardDescription>}
          {children}
        </TextContainer>
      </CardButton>
      <ImageContainer>{image}</ImageContainer>
    </Container>
  );
};

export default CardImage;
