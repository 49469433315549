import { Api } from "../../services/api";
import { IUser } from "./types";

export function setUserOnLocalStorage(user: IUser | null) {
  localStorage.setItem("user", JSON.stringify(user));
}

export function getUserOnLocalStorage() {
  const storagedUser = localStorage.getItem("user");
  
  if (!storagedUser) {
    return null;
  }
  
  const user = JSON.parse(storagedUser);
  return user ?? null;
}

export async function loginRequest(login: string, password: string) {
  try {
    const request = await Api.post("users/login", { login, password });
    return request.data;
  } catch (error) {
    return null;
  }
}
