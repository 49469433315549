import React from "react";
import { Container } from "@mui/material";
import CardImage from "../../components/CardImage";

import {
  InboxOutlined,
  RiseOutlined,
  DollarCircleOutlined,
} from "@ant-design/icons";

import SaleImage from "../../assets/saleIcon.png";
import CommissionIcon from "../../assets/commissionIcon.png";
import ProductIcon from "../../assets/productIcon.png";

const ProductImage: React.FC = () => <img src={ProductIcon} alt="estoque" />;

const SalesImage: React.FC = () => <img src={SaleImage} alt="vendas" />;

const CommissionImage: React.FC = () => (
  <img src={CommissionIcon} alt="comissões" />
);

const iconStyle: React.CSSProperties = {
  color: "gray",
  fontSize: "18px",
};

const Home: React.FC = () => {
  return (
    <Container>
      <CardImage
        icon={<InboxOutlined style={iconStyle} />}
        title="Estoque"
        description="Clique para conferir o estoque dos produtos na associação"
        image={<ProductImage />}
        link="/estoque"
      />

      <CardImage
        icon={<DollarCircleOutlined style={iconStyle} />}
        title="Comissões"
        description="Clique para conferir o dinheiro disponível para retirada"
        image={<CommissionImage />}
        link="/comissoes"
      />

      <CardImage
        icon={<RiseOutlined style={iconStyle} />}
        title="Vendas"
        description="Clique para conferir o histórico de vendas"
        image={<SalesImage />}
        link="/vendas"
      />
    </Container>
  );
};

export default Home;
