import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { Api } from "../../services/api";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { Snackbar } from "@mui/material";

const theme = createTheme();

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function ChangePassword() {
  const query = useQuery();
  const navigate = useNavigate();
  const token = query.get("token");
  const isRecovery = query.get("isPasswordRecovery");

  useEffect(() => {
    if (!token || !isRecovery) {
      return navigate("/");
    }
  }, []);

  const { register, handleSubmit } = useForm();

  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [errorMessageToastr, setErrorMessageToastr] = useState<boolean>(false);

  async function handleChangePassword(formData: {
    password: string;
  }): Promise<void> {
    setIsFetching(true);
    try {
      const request = await Api.put(
        "users/password-recovery",
        {
          password: formData.password.trim(),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setIsFetching(false);
      return navigate("/");
    } catch (error) {
      setIsFetching(false);
      setErrorMessageToastr(true);
      return navigate("/");
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            component="form"
            sx={{ mt: 1, display: "flex", flexDirection: "column" }}
            onSubmit={handleSubmit(handleChangePassword)}
          >
            <Typography
              component="h1"
              variant="h5"
              sx={{ marginBottom: 1, alignSelf: "center" }}
            >
              Último passo!
            </Typography>
            <Typography
              component="p"
              sx={{ marginBottom: 1, alignSelf: "center" }}
            >
              É só colocar a nova senha abaixo e tudo já estará pronto para usar
              😊
            </Typography>
            <TextField
              margin="normal"
              required
              fullWidth
              label="Senha"
              type="password"
              id="password"
              disabled={isFetching}
              autoFocus
              autoComplete="current-password"
              {...register("password", {
                min: 3,
              })}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={isFetching}
              sx={{ mt: 3, mb: 2 }}
            >
              Enviar
            </Button>
          </Box>
        </Box>
      </Container>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={errorMessageToastr}
        autoHideDuration={2500}
        onClose={() => setErrorMessageToastr(false)}
      >
        <Alert
          onClose={() => setErrorMessageToastr(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          Erro ao alterar a Senha
        </Alert>
      </Snackbar>
    </ThemeProvider>
  );
}
