import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled(Link)`
  padding-bottom: 26px;
  margin: 22px;
  background: transparent;
  position: relative;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
  display: flex;
`;

export const CardButton = styled.div<{hasImage?: boolean}>`
  box-shadow: 4px 3px 5px 3px #f2f2f2;
  width: 100%;
  border-radius: 12px;
  padding: 26px;
  color: #2d2d2d;

  div { 
    width: ${props => props.hasImage ? '65%' : '100%'};
  }
`;

export const TextContainer = styled.div`
  width: 65%;
  background: transparent;
`;

export const ImageContainer = styled.div`
  position: absolute;
  width: 30%;
  bottom: 0;
  right: 0;
  display:flex;
  align-items: center;
  justify-content: center;
`;

export const CardTitle = styled.h3`
  font-size: x-large;
  font-weight: bold;
  color: #2f2f2f;
  margin: 10px 0 5px 0;
`;

export const CardDescription = styled.p`
  font-size: medium;
  font-weight: normal;
  line-height: 24px;
  color: gray;
  margin: 0;
`;