import styled from "styled-components";

export const Title = styled.h1`
  font-size: large;
`;

export const Description = styled.p`
  margin: 0;
  color: #9f9f9f;
  font-size: small;
`;
