import styled from "styled-components";

import { colors } from "../../constants/colors";
import { Link } from "react-router-dom";

export const NavBarContainer = styled.nav`
  overflow: hidden;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 111px;
  display: flex;
  background-color: transparent;
  border: none;
  box-shadow: none;
  padding: 0;
  margin: 0;
  justify-content: center;
  align-items: flex-end;
`;

export const LinkButton = styled(Link)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  text-align: center;
  font-weight: 500;
  -webkit-tap-highlight-color: transparent;
`;

export const Icon = styled.span`
  position: relative;
  display: block;
  line-height: 75px;
  font-size: 1.5rem;
  text-align: center;
  transition: 0.5s;
  color: ${colors.darkGray};
`;

export const Title = styled.span`
  position: absolute;
  color: ${colors.darkGray};
  font-weight: 400;
  font-size: 0.75em;
  letter-spacing: 0.05em;
  transition: 0.5s;
  opacity: 0;
  transform: translateY(20px);
`;

export const ActiveIndicator = styled.div`
  position: absolute;
  top: -60%;
  height: 70px;
  width: 70px;
  background: ${colors.lightGreen};
  border-radius: 50%;
  border: solid 6px ${colors.white};
  background-clip: padding-box;
  transition: 0.5s;

  &:before {
    content: "";
    position: absolute;
    top: 52%;
    left: -22px;
    width: 20px;
    height: 20px;
    background: transparent;
    border-top-right-radius: 22px;
    box-shadow: 0px -10px 0 0 ${colors.white};
  }

  &:after {
    content: "";
    position: absolute;
    top: 52%;
    right: -22px;
    width: 20px;
    height: 20px;
    background: transparent;
    border-top-left-radius: 22px;
    box-shadow: 0px -10px 0 0 ${colors.white};
  }

  &.active1 {
    left: calc(((100% / 4) / 2) - 41px + (100% / 4 * 0));
  }

  &.active2 {
    left: calc(((100% / 4) / 2) - 41px + (100% / 4 * 1));
  }

  &.active3 {
    left: calc(((100% / 4) / 2) - 41px + (100% / 4 * 2));
  }

  &.active4 {
    left: calc(((100% / 4) / 2) - 41px + (100% / 4 * 3));
  }
`;

export const List = styled.ul`
  height: 70px;
  border-radius: 10px;
  background-color: ${colors.lightGray};
  position: relative;
  padding: 0;
  margin: 0;
  display: flex;
  flex: 1;
`;

export const Item = styled.li`
  flex: 1;
  position: relative;
  list-style: none;
  height: 70px;
  z-index: 1;

  &.active ${LinkButton} ${Icon} {
    transform: translateY(-35px);
    color: ${colors.white};
  }

  &.active ${LinkButton} ${Title} {
    opacity: 1;
    transform: translateY(12px);
  }
`;
