import styled from "styled-components";
import { colors } from "../../constants/colors";
import { Accordion } from "@mui/material";

export const BalanceContainer = styled.div`
  margin-top: 10px;
`;

export const BalanceDetailText = styled.p`
  font-size: medium;
  font-weight: normal;
  line-height: 24px;
  color: lightGray;
  margin: 0;
`;

export const BalanceText = styled.h3`
  margin: 16px 0 0 0;
  font-size: x-large;
  color: ${colors.lightGreen};
`;

export const BalanceSeparator = styled.hr`
  height: 1px;
  background-color: lightGray;
  border: none;
`;

export const Title = styled.h1`
  font-size: x-large;
  color: ${colors.darkGray};
`;

export const CustomAccordion = styled(Accordion)`
  box-shadow: 4px 3px 5px 3px #f2f2f2;
  border-radius: 12px !important;
  margin-bottom: 12px;

  &:before{
    background-color: transparent;
  }
`;