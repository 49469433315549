import { Link, Typography } from "@mui/material";
import React from "react";

const Copyright: React.FC<any> = (props) => {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link
        color="inherit"
        href="https://universoorganiconv.com.br"
        target="_blank"
        rel="noopener"
      >
        Universo Orgânico
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
};

export default Copyright;
