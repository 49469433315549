import styled from "styled-components";

export const Container = styled.div`
  padding-bottom: 12px;
  margin: 6px 40px;
  background: transparent;
  position: relative;
  display: flex;
`;

export const CardInformation = styled.div<{cardStatus: string}>`
  width: 100%;
  box-shadow: 0px 5px 5px -2px #f2f2f2;
  border-radius: 12px;
  padding: 26px;
  color: #2d2d2d;
  border-top: 4px solid ${props => props.cardStatus};
`;

export const ProductText = styled.h3`
  font-size: large;
  font-weight: bold;
  color: #2f2f2f;
  margin: 10px 0 5px 0;
`;

export const ImageContainer = styled.div`
  position: absolute;
  width: 20%;
  bottom: 0;
  right: 0;
  display:flex;
  align-items: center;
  justify-content: center;

  img {
    width: 48px;
  }
`;
