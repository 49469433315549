import styled from 'styled-components';

export const Container = styled.div`
  padding: 16px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Title = styled.h1`
  padding: 0;
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  color: #6d6d6d;
`;

export const LogoutContainerButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const LogoutText = styled.span`
  margin-top: 3px;
  font-size: 10px;
  font-weight: bold;
  text-transform: uppercase;
`;